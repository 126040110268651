html {
  scroll-behavior: smooth;
}
.header-logo img {
  width: 10vw;
}
@media screen and (max-width: 900px) {
  .header-logo img {
    margin-top: 1vh;
    width: 20vw;
  }
}
.menu-item span {
  color: #000000;
}
.menu-item i:first-child {
  color: #5f5f5f;
}
.menu-item i:last-child {
  color: #aaa;
}
.toggle-menu-header {
  color: #ffffff;
}
.toggle-menu-icon {
  background-color: #5d9cec;
  color: #ffffff;
}
input[id="toggle-menu"] + label .l1,
input[id="toggle-menu"] + label .l2,
input[id="toggle-menu"] + label .l3 {
  background-color: #9c0d6b;
}
.submenu-item label {
  color: #ffffff;
}
input[data-submenu-items] + label:before {
  color: #aaa;
}
input[data-submenu-items] + label:before {
  color: #ffffff;
}
input[data-submenu-items]:checked + label:before {
  color: #ed5565;
}
.submenu-wrapper .menu-item .fa-angle-right {
  color: #666;
}
.submenu-wrapper a {
  color: #aaa;
}
.sub_info {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2vh;
}
.text-center {
  text-align: center;
}
.text-feed {
  color: #9c0d6b;
}

.sub_info h6 {
  font-weight: 500;
  line-height: 30px;
}
.decoration-fancy .rate-svg {
  color: #9c0d6b;
  font-size: 3.5rem;
}
.sub_info svg {
  margin: 1vh;
  color: #9c0d6b;
}
.contact_crowne_logo_menu {
  margin-top: 2vh;
  width: 34%;
}

.justify-content-center {
  justify-content: center;
}
header {
  width: 50%;
  display: inline-block;
  background-color: #fff;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
}
.header-icon-1,
.header-icon-2 {
  color: #ffffff;
}

.footer {
  background-color: #141414;
}
.footer .decoration {
  background-color: rgba(255, 255, 255, 0.05);
}
.menu-item em {
  color: #ffffff;
  font-weight: 500;
  font-size: 12px;
  font-style: normal;
}

.cont {
  text-align: center;
  padding: 5px 0;
  overflow: hidden;
}

.feed-star input {
  border: 1px solid #9c0d6b;
  border-radius: 12px;
  margin: 2vh;
  padding: 1vh;
  width: 60vw;
}
.feed-star textarea {
  border: 1px solid #9c0d6b;
  border-radius: 12px;
  margin: 2vh;
  padding: 1vh;
  width: 60vw;
}

div.stars {
  width: 270px;
  display: inline-block;
}

input.star {
  display: none;
}

label.star {
  float: right;
  padding: 10px;
  font-size: 36px;
  color: #444;
  transition: all 0.2s;
}

input.star:checked ~ label.star:before {
  content: "\f005";
  color: #9c0d6b;
  transition: all 0.25s;
}

input.star-5:checked ~ label.star:before {
  color: #9c0d6b;
  text-shadow: 0 0 20px #952;
}

input.star-1:checked ~ label.star:before {
  color: #4d0734;
}

label.star:hover {
  transform: rotate(-15deg) scale(1.3);
}

label.star:before {
  content: "\f006";
  font-family: FontAwesome;
}

.rev-box {
  overflow: hidden;
  height: 0;
  width: 100%;
  transition: all 0.25s;
}

textarea.review {
  background: #222;
  border: none;
  width: 100%;
  max-width: 100%;
  height: 100px;
  padding: 10px;
  box-sizing: border-box;
  color: #eee;
}

label.review {
  display: block;
  transition: opacity 0.25s;
}

input.star:checked ~ .rev-box {
  height: 125px;
  overflow: visible;
}

.menu-item strong {
  color: #fc6e51;
  font-weight: 400;
  font-size: 16px;
}

.bg-teal-light {
  background-color: #1abc9c;
  color: #ffffff;
}
.bg-teal-dark {
  background-color: #16a085;
  color: #ffffff;
}
.border-teal-light {
  border: solid 1px #1abc9c;
}
.border-teal-dark {
  border: solid 1px #16a085;
}
.color-teal-light {
  color: #1abc9c;
}
.color-teal-dark {
  color: #16a085;
}
.bg-green-light {
  background-color: #2ecc71;
  color: #ffffff;
}
.bg-green-dark {
  background-color: #2abb67;
  color: #ffffff;
}
.border-green-light {
  border: solid 1px #2ecc71;
}
.border-green-dark {
  border: solid 1px #2abb67;
}
.color-green-light {
  color: #2ecc71;
}
.color-green-dark {
  color: #2abb67;
}
.bg-blue-light {
  background-color: #3498db;
  color: #ffffff;
}
.bg-blue-dark {
  background-color: #2980b9;
  color: #ffffff;
}
.border-blue-light {
  border: solid 1px #3498db;
}
.border-blue-dark {
  border: solid 1px #2980b9;
}
.color-blue-light {
  color: #3498db;
}
.color-blue-dark {
  color: #2980b9;
}
.bg-magenta-light {
  background-color: #9b59b6;
  color: #ffffff;
}
.bg-magenta-dark {
  background-color: #8e44ad;
  color: #ffffff;
}
.border-magenta-light {
  border: solid 1px #9b59b6;
}
.border-magenta-dark {
  border: solid 1px #8e44ad;
}
.color-magenta-light {
  color: #9b59b6;
}
.color-magenta-dark {
  color: #8e44ad;
}
.bg-night-light {
  background-color: #34495e;
  color: #ffffff;
}
.bg-night-dark {
  background-color: #2c3e50;
  color: #ffffff;
}
.border-night-light {
  border: solid 1px #34495e;
}
.border-night-dark {
  border: solid 1px #2c3e50;
}
.color-night-light {
  color: #34495e;
}
.color-night-dark {
  color: #2c3e50;
}
.bg-yellow-light {
  background-color: #e67e22;
  color: #ffffff;
}
.bg-yellow-dark {
  background-color: #e86f2a;
  color: #ffffff;
}
.border-yellow-light {
  border: solid 1px #e67e22;
}
.border-yellow-dark {
  border: solid 1px #f27935;
}
.color-yellow-light {
  color: #f1c40f;
}
.color-yellow-dark {
  color: #f39c12;
}
.bg-orange-light {
  background-color: #f9690e;
  color: #ffffff;
}
.bg-orange-dark {
  background-color: #d35400;
  color: #ffffff;
}
.border-orange-light {
  border: solid 1px #f9690e;
}
.border-orange-dark {
  border: solid 1px #d35400;
}
.color-orange-light {
  color: #e67e22;
}
.color-orange-dark {
  color: #d35400;
}
.bg-red-light {
  background-color: #e74c3c;
  color: #ffffff;
}
.bg-red-dark {
  background-color: #c0392b;
  color: #ffffff;
}
.border-red-light {
  border: solid 1px #e74c3c;
}
.border-red-dark {
  border: solid 1px #c0392b;
}
.color-red-light {
  color: #e74c3c;
}
.color-red-dark {
  color: #c0392b;
}
.bg-pink-light {
  background-color: #fa6a8e;
  color: #ffffff;
}
.bg-pink-dark {
  background-color: #fb3365;
  color: #ffffff;
}
.border-pink-light {
  border: solid 1px #fa6a8e;
}
.border-pink-dark {
  border: solid 1px #fb3365;
}
.color-pink-light {
  color: #fa6a8e;
}
.color-pink-dark {
  color: #fb3365;
}
.bg-gray-light {
  background-color: #bdc3c7;
  color: #ffffff;
}
.bg-gray-dark {
  background-color: #95a5a6;
  color: #ffffff;
}
.border-gray-light {
  border: solid 1px #bdc3c7;
}
.border-gray-dark {
  border: solid 1px #95a5a6;
}
.color-gray-light {
  color: #bdc3c7;
}
.color-gray-dark {
  color: #95a5a6;
}
.bg-white {
  background-color: #ffffff;
}
.color-white {
  color: #ffffff;
}
.border-white {
  border: solid 1px #ffffff;
}
.bg-black {
  background-color: #000000;
}
.color-black {
  color: #000000;
}
.border-black {
  border: solid 1px #000000;
}
.color-heading {
  color: #676767;
}

.facebook-bg {
  background-color: #3b5998;
  color: #ffffff;
}
.linkedin-bg {
  background-color: #0077b5;
  color: #ffffff;
}
.twitter-bg {
  background-color: #4099ff;
  color: #ffffff;
}
.google-bg {
  background-color: #d34836;
  color: #ffffff;
}
.whatsapp-bg {
  background-color: #34af23;
  color: #ffffff;
}
.pinterest-bg {
  background-color: #c92228;
  color: #ffffff;
}
.sms-bg {
  background-color: #27ae60;
  color: #ffffff;
}
.mail-bg {
  background-color: #3498db;
  color: #ffffff;
}
.dribbble-bg {
  background-color: #ea4c89;
  color: #ffffff;
}
.tumblr-bg {
  background-color: #2c3d52;
  color: #ffffff;
}
.reddit-bg {
  background-color: #336699;
  color: #ffffff;
}
.youtube-bg {
  background-color: #d12827;
  color: #ffffff;
}
.phone-bg {
  background-color: #27ae60;
  color: #ffffff;
}
.skype-bg {
  background-color: #12a5f4;
  color: #ffffff;
}
.facebook-color {
  color: #3b5998;
}
.linkedin-color {
  color: #0077b5;
}
.twitter-color {
  color: #4099ff;
}
.google-color {
  color: #d34836;
}
.whatsapp-color {
  color: #34af23;
}
.pinterest-color {
  color: #c92228;
}
.sms-color {
  color: #27ae60;
}
.mail-color {
  color: #3498db;
}
.dribbble-color {
  color: #ea4c89;
}
.tumblr-color {
  color: #2c3d52;
}
.reddit-color {
  color: #336699;
}
.youtube-color {
  color: #d12827;
}
.phone-color {
  color: #27ae60;
}
.skype-color {
  color: #12a5f4;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

h1 {
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;
}
h2 {
  font-size: 22px;
  line-height: 32px;
  font-weight: 500;
}
h3 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
}
h4 {
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
}
h5 {
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
}
h6 {
  font-size: 14px;
  line-height: 22px;
  font-weight: 800;
}
.decoration {
  margin-bottom: 30px;
}
.ultrathin {
  font-weight: 200;
}
.thin {
  font-weight: 300;
}
.thiner {
  font-weight: 400;
}
.boder {
  font-weight: 600;
}
.bold {
  font-weight: 700;
}
.ultrabold {
  font-weight: 800;
}
.capitalize {
  text-transform: capitalize;
}
.italic {
  font-style: italic;
}
.small-text {
  font-size: 20px;
  display: block;
}
.center-text {
  text-align: center;
  display: block;
}
.right-text {
  text-align: right;
}
.uppercase {
  text-transform: uppercase;
}
.boxed-text {
  width: 85%;
  margin: 0px auto 30px auto;
}
.boxed-image {
  width: 75%;
  margin: 0px auto 30px auto;
}
.round-image {
  border-radius: 500px;
}
p a {
  display: inline;
}
.disabled {
  display: none;
}
.heading-icon {
  font-size: 40px;
}
.heading-small {
  font-size: 26px;
}
.heading-large {
  font-size: 32px;
}
.heading-huge {
  font-size: 38px;
}
.heading-medium {
  font-size: 28px;
}

.container {
  margin-bottom: 30px;
}

.full-bottom h4 {
  font-size: 12px;
  font-weight: 700;
}
.double-bottom {
  margin-bottom: 60px;
}
.no-bottom {
  margin-bottom: 0px;
}
.full-top {
  margin-top: 25px;
}
.half-bottom {
  margin-bottom: 15px;
}
.half-top {
  margin-top: 15px;
}
.quarter-bottom {
  margin-bottom: 5px;
}
.hidden {
  display: none;
}
.left-column {
  width: 45%;
  margin-right: 5%;
  float: left;
}
.right-column {
  width: 45%;
  margin-left: 5%;
  float: left;
}
.one-third-left {
  float: left;
  width: 29%;
  margin-right: 1%;
}
.one-third-center {
  float: left;
  width: 29%;
  margin-left: 5%;
  margin-right: 5%;
}
.one-third-right {
  float: left;
  width: 29%;
  margin-left: 1%;
}
.clear {
  clear: both;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  outline: none;
  font-size-adjust: none;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  -webkit-transform: translate3d(1, 1, 1);
  transform: translate3d(1, 1, 1);
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div,
a,
p,
img,
blockquote,
form,
fieldset,
textarea,
input,
label,
iframe,
code,
pre {
  display: block;
  position: relative;
}

p {
  line-height: 30px;
  font-weight: 400;
  color: #666666;
  font-size: 13px;
  margin-bottom: 30px;
}

a {
  text-decoration: none;
  color: black !important;
}

.black_link {
  color: #000000 !important;
}

.icon-list {
  list-style: none;
  font-size: 14px;
  line-height: 28px;
  color: #666666;
}
.icon-list i {
  width: 30px;
}

.center-icon {
  width: 80px;
  height: 80px;
  border-radius: 80px;
  border: solid 1px rgba(0, 0, 0, 0.5);
  text-align: center;
  line-height: 80px;
  font-size: 24px;
  margin: 0px auto 30px auto;
  display: block;
}

.decoration,
.decoration-no-bottom {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
}

.deco {
  height: 1px;
  margin-bottom: 30px;
}

.deco-box .deco {
  width: 10%;
  float: left;
  height: 5px;
}

.decoration-margins {
  margin: 0px 20px 30px 20px;
}
.comment_area {
  border: 2px solid #9c0d6b;
  border-radius: 12px;
  width: 100%;
  height: 8vh;
  padding: 1vh;
}
.menu * {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.menu-item {
  transition: 1s all ease;
}

.menu-item.active {
  opacity: 0.4;
}

@media screen and (max-width: 567.98px) {
  .fixed-bar {
    justify-content: start !important;
  }

  ul.menu-list li {
    font-size: 12px !important;
    line-height: 40px;
    padding-bottom: 5px;
  }
}

@media (min-height: 300px) and (max-height: 450px) {
  .menu-clear {
    height: 10px;
  }
  .menu-scroll {
    padding-bottom: 35px;
  }
}
@media (min-height: 450px) and (max-height: 500px) {
  .menu-clear {
    height: 40px;
  }
  .menu-scroll {
    padding-bottom: 45px;
  }
}
@media (min-height: 500px) and (max-height: 600px) {
  .menu-clear {
    height: 55px;
  }
  .menu-scroll {
    padding-bottom: 90px;
  }
}
@media (min-height: 600px) {
  .menu-clear {
    height: 75px;
  }
  .menu-scroll {
    padding-bottom: 120px;
  }
}

.menu-sidebar {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 9;
  width: 340px;
  transform: translateX(-300px);
  transition: all 450ms ease;
  -webkit-transform: translateX(-300px);
  -moz-transform: translateX(-300px);
  -ms-transform: translateX(-300px);
  -o-transform: translateX(-300px);
}

.menu-scroll {
  position: absolute;
  left: 60px;
  right: 0px;
  top: 0px;
  bottom: 0px;

  padding-top: 10px;
  overflow: hidden;
}

input[data-submenu-items] {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

input[id="toggle-menu"]:checked ~ #menu-sidebar {
  transform: translateX(0px);
  transition: all 450ms ease;
}

input[id="toggle-menu"]:checked ~ header {
  transform: translateX(100%) translateY(0px);
  transition: all 450ms ease;
}

input[id="toggle-menu"]:checked ~ .menu-hider {
  display: block;
}

input[id="toggle-menu"]:checked ~ .toggle-menu-header {
  transform: translateX(0) translateY(0px);
  transition: all 450ms ease;
}

input[id="toggle-menu"]:checked ~ .page-content {
  transform: translateX(100%) translateY(0px);
  transition: all 450ms ease;
}

.page-content {
  background-color: #ffffff;
  z-index: 9;
  transition: all 450ms ease;
  overflow-x: hidden;
}

.page-content-scroll {
  scroll-behavior: smooth;
  backdrop-filter: brightness(0.95);
  overflow-x: hidden;
  background: url(/public/crowne_bg.png);
}

.header-clear {
  padding-top: 60px;
}

.page-content-transparent {
  background-color: rgba(255, 255, 255, 0);
}

.menu-hider {
  position: fixed;
  top: 0px;
  left: 350px;
  bottom: 0px;
  right: 0px;
  z-index: 99999;
  display: none;
}

.body-bg {
  background-size: cover;
  background-position: top right;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 5;
  backdrop-filter: brightness(0.95);
}

.menu-item {
  text-align: center;
  height: 47px;
  line-height: 47px;
  display: block;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.menu-item i {
  position: absolute;
  height: 45px;
  width: 55px;
  line-height: 45px;
  text-align: center;
}

.active-item em {
  font-size: 16px;
  text-align: center;
  position: absolute;
  width: 4px;
  height: 4px;
  right: 25px;
  border-radius: 7px;
  top: 50%;
  margin-top: -2px;
}

.active-item strong {
  font-weight: 400;
}

.active-item .fa-circle {
  display: none;
}
.active-item span {
  margin-left: -3px;
}
.active-item i:first-child {
  margin-left: -3px;
}
.menu-item i:first-child {
  left: 0px;
  font-size: 15px;
}
.menu-item i:last-child {
  right: 0px;
}
.menu-item .fa-circle {
  font-size: 4px;
}
.menu-item .fa-angle-down {
  font-size: 8px;
}

.toggle-menu-header {
  width: 50px;
  line-height: 60px;
  text-align: center;
  top: 0px;
  z-index: 99;
  height: 50px;
  top: 0px;
  left: 0px;
  cursor: pointer;
  transition: all 4s50ms ease;
}

.toggle-menu-icon .l1 {
  top: -7px;
}
.toggle-menu-icon .l2 {
  opacity: 0;
}
.toggle-menu-icon .l3 {
  top: -8px;
}

input[id="toggle-menu"] {
  display: none;
}
input[id="toggle-menu"] + label:before {
  font-family: FontAwesome;
  display: inline-block;
}
input[id="toggle-menu"] + label .l1,
input[id="toggle-menu"] + label .l2,
input[id="toggle-menu"] + label .l3 {
  height: 2px;
  width: 26px;
  position: absolute;
  left: 2%;
  transition: all 250ms ease;
}

input[id="toggle-menu"] + label .l1 {
  margin-top: 25px;
}
input[id="toggle-menu"] + label .l2 {
  margin-top: 33px;
}
input[id="toggle-menu"] + label .l3 {
  margin-top: 41px;
}

input[id="toggle-menu"]:checked + label .l1 {
  transform: rotate(45deg);
  margin-top: 30px;
  transition: all 250ms ease;
}
input[id="toggle-menu"]:checked + label .l2 {
  opacity: 0;
  transition: all 250ms ease;
}
input[id="toggle-menu"]:checked + label .l3 {
  transform: rotate(-45deg);
  margin-top: 30px;
  transition: all 250ms ease;
}

input[id="toggle-menu"] {
  display: none;
}
input[id="toggle-menu"] + label:before {
  font-family: FontAwesome;
  display: inline-block;
}

.submenu-item label {
  height: 50px;
  cursor: pointer;
}

input[class="toggle-submenu"] {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.submenu-wrapper {
  height: 0px;
  overflow: hidden;
  color: white;
  transition: all 250ms ease;
}

input[data-submenu-items] {
  display: none;
}
input[data-submenu-items] + label:before {
  font-family: FontAwesome;
  position: absolute;
  right: 0px;
  height: 60px;
  width: 55px;
  line-height: 60px;
  text-align: center;
  font-size: 9px;
}

.submenu-wrapper a {
  height: 50px;
  font-size: 12px;
  line-height: 50px;
  border-bottom: none;
}

.submenu-wrapper a i {
  line-height: 55px;
}
.submenu-wrapper .menu-item .fa-angle-right {
  font-size: 8px;
  margin-top: -1px;
}
.submenu-wrapper a:first-child {
  margin-top: -5px;
}
.submenu-wrapper a:last-child {
  padding-bottom: 5px;
}

[data-submenu-items="2"]:checked ~ .submenu-wrapper {
  height: 100px;
}
[data-submenu-items="3"]:checked ~ .submenu-wrapper {
  height: 150px;
}
[data-submenu-items="4"]:checked ~ .submenu-wrapper {
  height: 200px;
}
[data-submenu-items="5"]:checked ~ .submenu-wrapper {
  height: 250px;
}
[data-submenu-items="6"]:checked ~ .submenu-wrapper {
  height: 300px;
}
[data-submenu-items="7"]:checked ~ .submenu-wrapper {
  height: 350px;
}
[data-submenu-items="8"]:checked ~ .submenu-wrapper {
  height: 400px;
}
[data-submenu-items="9"]:checked ~ .submenu-wrapper {
  height: 450px;
}
[data-submenu-items="10"]:checked ~ .submenu-wrapper {
  height: 500px;
}
[data-submenu-items="11"]:checked ~ .submenu-wrapper {
  height: 550px;
}
[data-submenu-items="12"]:checked ~ .submenu-wrapper {
  height: 600px;
}
[data-submenu-items="13"]:checked ~ .submenu-wrapper {
  height: 650px;
}
[data-submenu-items="14"]:checked ~ .submenu-wrapper {
  height: 700px;
}
[data-submenu-items="15"]:checked ~ .submenu-wrapper {
  height: 750px;
}

/* header {
  display: flex;
  position: relative;
  height: 55px;
  width: 100%;
  z-index: 98;
  transition: all 450ms ease;
  background-color: transparent;
  align-items: flex-end;
  flex-direction: column;
} */

.header-transparent header {
  border-bottom: none;
}
.header-transparent .page-content {
  padding-top: 0px;
}

.header-icon-1,
.header-icon-2 {
  position: absolute;
  line-height: 60px;
  text-align: center;
  width: 60px;
  display: block;
  font-size: 14px;
  background-color: transparent;
}

.header-icon-2 {
  right: 0px;
  top: 0px;
}
.justify-between-header {
  justify-content: space-between;
}
.logo-header-block {
  width: 33%;
  margin-top: 1vh;
  display: inline-block;
}
.header-logo {
  display: flex;
  justify-content: center;
}

.footer {
  padding-bottom: 5px;
  padding-top: 30px;
}
.footer-logo {
  background-repeat: no-repeat;
  background-size: 140px 35px;
  width: 142px;
  height: 35px;
  display: block;
  margin: 12px auto 20px auto;
}

.footer-socials a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-left: 2px;
  margin-right: 2px;
  text-align: center;
  float: left;
}

.footer-socials a {
  background-color: transparent;
  border: solid 1px rgba(255, 255, 255, 0.05);
  box-sizing: border-box;
}

.footer-socials {
  width: 220px;
  margin: 0px auto 30px auto;
}

.news-slider .caption {
  background-color: rgba(0, 0, 0, 0.8);
}

.addthis-wrapper {
  margin-left: -8px;
}

.contactField {
  font-family: "Roboto", sans-serif;
  height: 40px;
  line-height: 40px;
  line-height: 100%;
  width: 100%;
  display: block;
  border: solid 1px rgba(0, 0, 0, 0.1);
  text-indent: 10px;
  font-size: 13px;
  transition: all 250ms ease;
  margin-bottom: 20px;
}

.contactField:focus {
  border: solid 1px rgb(140, 193, 82);
  transition: all 250ms ease;
}

.contactTextarea {
  font-family: "Roboto", sans-serif;
  padding-top: 10px;
  min-height: 80px;
  line-height: 40px;
  line-height: 100%;
  width: 100%;
  display: block;
  border: solid 1px rgba(0, 0, 0, 0.1);
  text-indent: 10px;
  font-size: 13px;
  transition: all 250ms ease;
  margin-bottom: 30px;
}

.contactTextarea:focus {
  transition: all 250ms ease;
  border: solid 1px rgb(140, 193, 82);
}

.field-title {
  font-size: 13px;
  margin-bottom: 5px;
}

.field-title span {
  font-size: 10px;
  color: #cacaca;
  position: absolute;
  right: 0px;
  margin-top: 2px;
}

.buttonWrap {
  width: 100%;
  display: block;
  text-align: center;
  margin-bottom: 30px;
  appearance: none;
  -webkit-appearance: none;
  font-family: "Roboto", sans-serif;
  letter-spacing: 1px;
}

#contactDateField {
  line-height: 40px;
}
input,
textarea {
  appearance: none;
  -webkit-appearance: none;
}
textarea::placeholder {
  text-indent: 10px;
  color: #969696;
}
input::placeholder {
  font-size: 12px;
  line-height: 150%;
  color: #969696;
}
input[type="date"] {
  padding-left: 10px;
  box-sizing: border-box;
  text-indent: 0px;
  font-size: 12px;
  color: #969696;
}

input[type="date"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-outer-spin-button,
input[type="date"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator,
input[type="date"]::-webkit-clear-button {
  -webkit-appearance: none;
  display: none;
}

.contact-icon {
  color: #666666;
  line-height: 30px;
}

.contact-icon i {
  color: #1f1f1f;
  width: 30px;
}

.cover-bg-1 .cover-overlay {
  background-color: rgba(0, 0, 0, 0.7);
}

.cover-bg-2 .cover-overlay {
  background-color: rgba(0, 0, 0, 0.85);
}

.cover-class {
  background-size: cover;
  background-position: center center;
  height: 90vh;
  border-bottom: solid 1px rgba(255, 255, 255, 0.05);
}

.cover-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.cover-content {
  z-index: 999;
  position: absolute;
  height: 200px;
  width: 300px;
  left: 50%;
  margin-left: -150px;
  top: 50%;
  margin-top: -150px;
}

.cover-logo {
  background-image: url(/public/logo_light_large.png);
  width: 200px;
  height: 53px;
  background-size: 200px 53px;
  margin: 0px auto 20px auto;
}

.cover-content p {
  text-align: center;
  max-width: 280px;
  color: #c4c4c4;
  margin: 0 auto;
}

.cover-cta {
  position: absolute;
  left: 50%;
  margin-left: -100px;
  margin-bottom: 20px;
  width: 200px;
  height: 45px;
  line-height: 45px;
  border-radius: 5px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 12px;
  z-index: 999;
  bottom: 70px;
  border: solid 1px rgba(255, 255, 255, 0.2);
}

.cover-boxes {
  overflow: hidden;
  margin-top: -3px;
  z-index: 999;
  position: relative;
  width: 101%;
}

.cover-boxes a {
  background-color: rgba(0, 0, 0, 0.9);
  width: 33.3%;
  height: 100px;
  float: left;
  box-sizing: border-box;
  border-left: solid 1px rgba(255, 255, 255, 0.05);
  border-bottom: solid 1px rgba(255, 255, 255, 0.05);
}

.cover-boxes a i {
  position: absolute;
  width: 100%;
  display: block;
  font-size: 24px;
  line-height: 75px;
  text-align: center;
}

.cover-boxes a em {
  display: block;
  text-align: center;
  color: #ffffff;
  line-height: 130px;
  font-style: normal;
  font-size: 12px;
}

.heading-cursive {
  font-family: "Raleway", sans-serif;
}
.heading-serif {
  font-family: "Raleway", sans-serif;
}

.home-bar {
  border-radius: 10px;
  margin-top: 0px;
}

.home-bar-large-icons i {
  transform: scale(1.5, 1.5);
}

.home-bar-2-icons a {
  width: 50%;
}
.home-bar-3-icons a {
  width: 33.3%;
}
.home-bar-4-icons a {
  width: 25%;
}

.home-bar a i {
  display: block;
  font-size: 20px;
  margin-bottom: -10px;
}

.home-thumbnails.home-1-thumbs strong {
  font-size: 24px;
  font-weight: 800;
  text-transform: uppercase;
}

.home-thumbnails.home-1-thumbs em {
  font-size: 12px;
  padding-top: 10px;
}

.home-thumbnails {
  margin-bottom: 20px;
}
.home-thumbnails.home-2-thumbs a:nth-child(2n + 2) {
  margin-left: 2%;
}
.home-thumbnails.home-2-thumbs a:nth-child(2n + 1) {
  margin-right: 2%;
}

.home-thumbnails.home-2-thumbs a {
  width: 48%;
  text-decoration: none;
  float: left;
}

.home-thumbnails.home-3-thumbs a:nth-child(3n + 3) {
  margin-left: 1%;
}
.home-thumbnails.home-3-thumbs a:nth-child(3n + 2) {
  margin-left: 1%;
}
.home-thumbnails.home-3-thumbs a:nth-child(2n + 1) {
  margin-right: 1%;
}

.home-thumbnails.home-3-thumbs a {
  width: 31%;
  text-decoration: none;
  float: left;
}

.home-thumbnails a u {
  position: absolute;
  right: 0px;
  left: 0px;
  font-size: 12px;
  color: #1f1f1f;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  margin-top: -22px;
  padding: 4px 15px;
  font-weight: 700;
  display: none;
}

.home-thumbnails a strong {
  font-size: 15px;
  display: block;
  font-weight: 400;
  line-height: 25px;
  padding-top: 5px;
  text-align: center;
}

.home-thumbnails a span {
  font-size: 14px;
  text-align: center;
  display: block;
  font-weight: 700;
  margin-bottom: 10px;
}

.home-thumbnails a em {
  font-size: 10px;
  display: block;
  font-weight: 400;
  color: #868686;
  padding-bottom: 10px;
  text-align: center;
}

.home-thumbnails amp-img {
  border-radius: 5px;
  overflow: hidden;
}

.home-quote {
  font-size: 17px;
  padding-left: 20px;
  padding-right: 20px;
  font-style: italic;
  font-weight: 300;
  color: #8b8b8b;
  line-height: 34px;
  text-align: center;
}

.home-quote a {
  padding-top: 10px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
}

.testimonials:before {
  content: "\f10e";
  font-family: FontAwesome;
  position: absolute;
  font-size: 140px;
  color: rgba(255, 206, 84, 0.05);
  width: 140px;
  height: 140px;
  text-align: center;
  display: block;
  left: 50%;
  margin-left: -70px;
  margin-top: 170px;
}

.testimonials amp-img {
  width: 80px;
  margin: 0 auto;
  border-radius: 80px;
  display: block;
  margin-bottom: 20px;
}

.testimonials h1 {
  font-size: 17px;
  line-height: 40px;
  text-align: center;
  font-weight: 300;
  color: #555555;
  font-style: italic;
  padding: 0px 35px 0px 35px;
}

.testimonials a {
  display: block;
  text-align: center;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  padding-top: 30px;
  color: #000000;
}

.testimonials span {
  width: 115px;
  margin: 0 auto;
  display: block;
  margin-top: 20px;
  font-size: 20px;
}

.amp-carousel-button-next,
.amp-carousel-button-prev {
  top: 55px;
  background-image: none;
  background-color: transparent;
  color: #000000;
  font-size: 24px;
  text-align: center;
  width: 40px;
  line-height: 35px;
  display: block;
}

.amp-carousel-button-next:before {
  content: "\f105";
  font-family: FontAwesome;
}
.amp-carousel-button-prev:before {
  content: "\f104";
  font-family: FontAwesome;
}

.home-delivery {
  height: 80px;
}

.home-delivery i {
  font-size: 34px;
  line-height: 50px;
  width: 50px;
  text-align: center;
  display: block;
  position: absolute;
}

.home-delivery h1 {
  text-align: right;
  display: block;
  font-size: 18px;
}

.home-delivery em {
  text-align: right;
  display: block;
  font-size: 12px;
  color: #838383;
  font-style: normal;
}

ul.menu-list {
  padding: 0;
  overflow-x: hidden;
  list-style: none;
}
ul.menu-list li:before {
  float: left;
  width: 0;
  padding-top: 10px;
  color: #cacaca;
  white-space: nowrap;
}

ul.menu-list span:first-child {
  padding-right: 0.33em;
  font-weight: 400;
  font-size: 13px;
}

ul.menu-list span + span {
  float: right;
  margin-top: 8px;
  font-size: 11px;
  opacity: 0.5;
}

ul.menu-list span:nth-child(3) {
  display: block;
  clear: both;
  text-align: left;
  width: 100%;
  float: left;
  padding-left: 0.33em;
  padding-right: 0.33em;
  font-size: 11px;
  opacity: 0.5;
  margin-left: -5px;
  margin-bottom: 15px;
  margin-top: 8px;
}

ul.menu-list li {
  font-size: 13px;
  line-height: 16px;
  padding-top: 25px;
  border-bottom: 1px solid rgba(128, 128, 128, 0.281);
  padding-left: 2vw;
  padding-right: 2vw;
}

ul.menu-list sup {
  vertical-align: super;
  font-size: smaller;
}

.decoration-fancy span {
  text-align: center;
  display: flex;
  justify-content: center;
  position: relative;
  width: 82px;
  height: 75px;
  margin: 0 auto;
  display: block;
  font-size: 13px;
  z-index: 999;
}

.decoration-fancy i {
  position: absolute;
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  z-index: 9999;
  left: 50%;
  margin-left: -35px;
  top: -10px;
  font-size: 28px;
}
.decoration-fancy {
  display: flex;
  background-color: transparent;
  z-index: 98;
  transition: all 450ms ease;
}

.w-100 {
  width: 100%;
}

.menu-classic {
  height: 70px;
  margin-bottom: 10px;
}

.menu-classic i {
  right: 10px;
  font-size: 34px;
  line-height: 50px;
  width: 50px;
  text-align: center;
  display: block;
  position: absolute;
}

.menu-classic h1 {
  text-align: left;
  display: block;
}

.menu-classic em {
  text-align: left;
  display: block;
  font-size: 12px;
  color: #838383;
  font-style: normal;
}
.toggle-menu-header {
  display: inline-block;
  width: 34%;
}
.language {
  z-index: 9999999999999999999;
  display: inline-block;
  width: 33%;
  vertical-align: middle;
}
.language div {
  justify-content: flex-end;
  margin-right: 2%;
}
.side_menu_headerr_shild_one {
  display: inline-block;
  /* width: 33%; */
}
.side_menu_headerr_child_two {
  display: inline-block;
  /* width: 33%; */
}
.side_menu_headerr_child_one {
  width: 50%;
}
.side_menu_headerr_child_two {
  width: 50%;
}

ul.menu-list-minimal {
  max-width: 40em;
  padding: 0;
  overflow-x: hidden;
  list-style: none;
}

ul.menu-list-minimal li {
  line-height: 40px;
  text-transform: uppercase;
  font-size: 12px;
}

ul.menu-list-minimal li:before {
  float: left;
  width: 0;
  padding-top: 10px;
  color: #cacaca;
  white-space: nowrap;
}

ul.menu-list-minimal span:first-child {
  padding-right: 0.33em;
  font-weight: 400;
}

ul.menu-list-minimal span + span {
  float: right;
  padding-left: 0.33em;
  margin-top: -3px;
}

.menu-bg {
  background-image: url(/public/body-bg.jpg);
  background-size: cover;
}

.button {
  display: inline-block;
  padding: 13px 25px;
  font-size: 12px;
}

.button-round {
  border-radius: 30px;
}
.button-full {
  display: block;
  text-align: center;
}
.button-center {
  max-width: 175px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  text-align: center;
}
.button:hover {
  opacity: 0.9;
}

.icon-square,
.icon-round {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  display: inline-block;
  margin-left: 6px;
  margin-right: 6px;
  margin-bottom: 10px;
  font-size: 14px;
}
.icon-square:hover,
.icon-round:hover {
  opacity: 0.9;
}
.icon-round {
  border-radius: 45px;
}

.booking-hours {
  width: 300px;
  margin: 0px auto 30px auto;
}

.booking-hours a {
  width: 28%;
  float: left;
  height: 30px;
  line-height: 27px;
  text-align: center;
  border: solid 1px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  border-radius: 50px;
  color: #1f1f1f;
  font-size: 13px;
  transition: all 250ms ease;
}

.booking-hours a:nth-child(2) {
  margin-left: 5%;
  margin-right: 5%;
}

.booking-hours a:hover {
  background-color: #000000;
  color: #ffffff;
  transition: all 250ms ease;
}

.location-box h6 {
  font-size: 12px;
  font-weight: 500;
  color: #666666;
  line-height: 16px;
  padding-top: 5px;
  padding-bottom: 10px;
  letter-spacing: 1.5px;
}

.location-box .button {
  padding: 12px 25px;
  font-size: 11px;
}

.review-box amp-img {
  width: 120px;
  height: 120px;
  border-radius: 60px;
  margin: 0px auto 15px auto;
}

.review-box h1 {
  top: 0px;
  left: 75px;
  font-size: 22px;
}

.review-box em {
  font-size: 11px;
  font-weight: 400;
  color: #909090;
  display: block;
}

.review-box strong {
  display: block;
  width: 92px;
  margin: 10px auto 15px auto;
}

.review-box strong i {
  font-size: 16px;
}

.review-box p {
  max-width: 300px;
  text-align: center;
  margin: 0px auto 30px auto;
  font-size: 18px;
  font-weight: 300;
  color: #5c5c5c;
  font-style: italic;
  line-height: 40px;
}

.heading-box {
  height: 120px;
  margin-bottom: 30px;
}

.heading-box h3 {
  z-index: 999;
  color: #ffffff;
  position: relative;
  padding-top: 35px;
  font-size: 22px;
  text-align: center;
}

.heading-box p {
  z-index: 999;
  position: relative;
  text-align: center;
}

.large-link {
  height: 45px;
  line-height: 45px;
  color: #666666;
  font-size: 13px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
}

.large-link i:last-child {
  width: 30px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  padding-right: 10px;
  color: #1f1f1f;
}

.large-link .fa-angle-right {
  position: absolute;
  right: 0px;
  height: 45px;
  line-height: 45px;
  text-align: center;
}

.accordion h4 {
  background-color: transparent;
  border: none;
}

.accordion h4 {
  font-size: 16px;
  line-height: 40px;
}

.accordion h4 i {
  height: 40px;
  line-height: 40px;
  position: absolute;
  right: 0px;
  font-size: 12px;
}

.nested-accordion h4 {
  font-size: 14px;
}

section[expanded] .fa-plus {
  transform: rotate(45deg);
}
section[expanded] .fa-angle-down {
  transform: rotate(180deg);
}
section[expanded] .fa-chevron-down {
  transform: rotate(180deg);
}

.amp-carousel-button {
  color: #ffffff;
}
.amp-carousel-button-next,
.amp-carousel-button-prev {
  background-color: rgba(0, 0, 0, 0.8);
  height: 40px;
  top: 50%;
  margin-top: -23px;
  line-height: 40px;
  background-size: 10px 10px;
  transform: scale(0.8, 0.8);
  cursor: pointer;
}

.amp-carousel-button-next {
  right: -5px;
}
.amp-carousel-button-prev {
  left: -5px;
}

.caption {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 65px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: rgba(0, 0, 0, 0.5);
}

.caption h4 {
  font-size: 14px;
  color: #ffffff;
  line-height: 20px;
  margin-top: 12px;
}

.caption h3 {
  color: #ffffff;
  margin-bottom: 5px;
  font-size: 16px;
  padding-top: 23px;
  line-height: 0px;
}

.caption p {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
}

.demo-icons a {
  color: #ffffff;
  width: 20%;
  height: 50px;
  float: left;
}
.demo-icons a i {
  color: #1f1f1f;
  font-size: 21px;
  width: 50px;
  height: 50px;
  float: left;
  text-align: center;
  overflow: hidden;
}

.highlight {
  margin-bottom: 10px;
}
.highlight span {
  padding: 3px 5px 3px 5px;
  margin-right: 2px;
}
ol ul {
  padding-left: 5px;
}
ol,
ul {
  line-height: 24px;
}
.icon-list {
  list-style: none;
  margin-left: 0px;
  padding-left: 0px;
}
.icon-list i {
  font-size: 10px;
}
.icon-list ul {
  list-style: none;
  padding-left: 10px;
}
.icon-list ul ul {
  padding-left: 10px;
}

.user-notification {
  text-align: left;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #27ae60;
  height: 50px;
  color: #ffffff;
  font-size: 12px;
  line-height: 24px;
  width: 70%;
  float: left;
}

.back_icon_qr {
  position: absolute;
  z-index: 99;
  font-size: 40px;
  color: white;
  left: 5px;
  top: 5px;
}

.user-notification button {
  background-color: #27ae60;
  color: #ffffff;
  height: 55px;
  position: fixed;
  right: 0px;
  bottom: 0px;
  width: 25%;
}

amp-social-share[type="twitter"],
amp-social-share[type="whatsapp"],
amp-social-share[type="sms"],
amp-social-share[type="facebook"],
amp-social-share[type="gplus"],
amp-social-share[type="email"],
amp-social-share[type="pinterest"],
amp-social-share[type="linkedin"] {
  background-image: none;
}

amp-social-share {
  font-family: FontAwesome;
  height: 40px;
  line-height: 40px;
  color: #ffffff;
  text-align: center;
  display: block;
  font-size: 16px;
  margin-right: 10px;
  margin-bottom: 5px;
}

amp-social-share[type="twitter"]:before {
  content: "\f099";
}
amp-social-share[type="facebook"]:before {
  content: "\f09a";
}
amp-social-share[type="gplus"]:before {
  content: "\f0d5";
}
amp-social-share[type="email"]:before {
  content: "\f0e0";
}
amp-social-share[type="pinterest"]:before {
  content: "\f231";
}
amp-social-share[type="linkedin"]:before {
  content: "\f0e1";
}
amp-social-share[type="whatsapp"]:before {
  content: "\f232";
}
amp-social-share[type="sms"]:before {
  content: "\f075 ";
}

.fixed-bar {
  justify-content: center;
  background-color: white;
  width: 100%;
  overflow-y: scroll;
  height: 60px;
  display: flex;
  top: 7vh;
  gap: 10px;
  padding-right: 2vh;
  position: fixed;
  z-index: 98;
  transition: all 450ms ease;
}

.fixed_bar_icon {
  width: 5vh;
}

.datepicker_btn {
  background-color: #9c0d6b;
  border-radius: 5px;
  font-size: 11px;
  padding: 7px;
  color: white;
  cursor: pointer;
}
.flex-column {
  flex-direction: column;
}
.feed_btn {
  background-color: #9c0d6b;
  border-radius: 12px;
  font-size: 17px;
  padding: 7px;
  color: white;
  margin-bottom: 5vh;
  cursor: pointer;
  text-align: center;
  margin-top: 1vh;
  width: 40vw;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}
.feed_btn_2 {
  background-color: #9c0d6b;
  border-radius: 5px;
  font-size: 17px;
  padding: 7px;
  color: white;
  cursor: pointer;
  text-align: center;
  margin-top: 1vh;
}
.feed_btn_2 span {
  color: white;
}

.category_btn {
  position: relative;
  top: 1.5vh;
  border-radius: 5px;
  width: max-content;
  padding: 10px;
  font-size: 11px;
}

.selected {
  background-color: #9c0d6b;
  color: white;
}

.center_div {
  box-shadow: 0 1px 19px -10px gray;
  border-radius: 9px;
  padding: 15px;
  margin-bottom: 5vh;
}

.alergens {
  display: flex;
  align-items: center;
  padding-top: 2vh;
}

.alergen_icons {
  width: 10%;
}

.alergens_p {
  margin-bottom: 0;
  margin-left: 1vh;
}
.info_p {
  margin-bottom: 0;
}

.mb-4 {
  margin-bottom: 4vh;
}

.mb-2 {
  margin-bottom: 2vh;
}

.mt-2 {
  margin-top: 2vh;
}

.small_alergen_icon {
  width: 1%;
}

.d-flex {
  display: flex;
}

.tabs {
  margin-bottom: 3vh;
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow-y: scroll;
}

.tabs button {
  background-color: lightgray;
  border: none;
  color: #888888;
  cursor: pointer;
  padding: 1px 1px 5px 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  transition: all 0.5s ease-out;
}

.tabs button:hover {
  color: black;
}

.tabs button:disabled {
  color: #9c0d6b;
  border-bottom: 1px solid #9c0d6b;
}

.tab_img {
  width: 20vh;
  margin-bottom: 10px;
}

.category_img {
  width: 15vh;
  cursor: pointer;
}

.item_price {
  color: #9c0d6b;
  font-weight: 500;
  margin-bottom: 10px;
}

.category_inner {
  width: 100%;
}
.font-12 {
  font-size: 12px;
}
.center_category_title {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #9c0d6b;
  margin-bottom: 20px;
}

.center_category_title h5 {
  font-weight: 700;
}

.center_category_title h6 {
  font-weight: 500;
  opacity: 0.6;
  font-weight: 700;
}

.react-modal-sheet-header {
  background-color: transparent !important;
}

.react-modal-sheet-content {
  border-radius: 15px !important;
}

.modal_detail_desc {
  margin-bottom: 1px;
  line-height: 20px;
}

.modal_detail_price {
  color: #9c0d6b;
  margin-bottom: 0;
}

.modal_detail {
  padding: 20px;
  background-color: white;
}

.opacity_low {
  color: #8e8888;
}

.react-modal-sheet-drag-indicator {
  background-color: #9c0d6b !important;
}

.back-button {
  cursor: pointer;
  background-color: white;
  position: absolute;
  top: 2vh;
  left: 2vh;
  padding: 10px;
  border-radius: 50%;
  height: 40px;
}

.back_btn_icon {
  font-size: 20px;
}

.accordion_one {
  background-color: rgba(255, 255, 255, 0.486) !important;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.1),
    0px 1px 1px 0px rgba(0, 0, 0, -1.86), 0px 1px 3px 0px rgba(0, 0, 0, -0.88) !important;
}
.accordion_two {
  background-color: rgba(255, 255, 255, 0.486) !important;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.1),
    0px 1px 1px 0px rgba(0, 0, 0, -1.86), 0px 1px 3px 0px rgba(0, 0, 0, -0.88) !important;
}

.room_title {
  text-align: center;
  padding-top: 3vh;
}

.button_tabs {
  display: flex;
  justify-content: space-around;
  width: 100%;
  overflow-y: scroll;
  padding-bottom: 3vh;
  gap: 20px;
  padding-left: 55vh;
}

.navigation_page_button {
  background-color: white;
}

.button_tabs .navigation_page_button a.active {
  background-color: #9c0d6b;
  color: white !important;
}

.button_tabs .navigation_page_button a {
  transition: 1s all ease;
  background-color: white;
  border: 1px solid #9c0d6b;
  border-radius: 5px;
  font-size: 12px;
  padding: 7px;
  width: 150px;
}

.wine_title {
  font-weight: 700;
  font-size: 12px;
}

.top_accordion {
  position: fixed;
  z-index: 98;
  background-color: white;
  top: 16vh;
}

.accordion_title_top {
  font-size: 12px;
  font-weight: 700;
}

.pages_tab {
  position: relative;
  display: flex;
  justify-content: space-between;
  text-align: center;
  font-size: 12px;
  gap: 10px;
  width: 100%;
  overflow-y: scroll;
  margin-bottom: 4vh;
  margin-top: 2vh;
}

.pages_tab a {
  background-color: white;
  border: 1px solid #9c0d6b;
  border-radius: 5px;
  min-width: 25vh;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
}

.pages_tab a.active {
  background-color: #9c0d6b;
  color: white !important;
}

.pages_tab.move_right .pages_tab {
  right: 17vh;
}

.react-modal-sheet-backdrop {
  background-color: #ffffff !important;
}

.breadcrumb {
  display: flex;
  padding-bottom: 2vh;
  padding-top: 2vh;
  padding-left: 1vh;
}

.main_menu {
  opacity: 0.5;
  font-size: 11px;
  font-weight: 500;
}
.sub_menu {
  font-size: 11px;
  font-weight: 700;
  padding-left: 5px;
}

.max-width-form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.modal_img_category {
  position: relative;
  z-index: 9;
  width: 100%;
}

.react-modal-sheet-container {
  z-index: 9 !important;
  height: calc(30% - env(safe-area-inset-top) - 34px) !important;
  width: 30% !important;
  bottom: 100px !important;
  left: 30px !important;
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
}

@media screen and (max-width: 1024px) {
  .react-modal-sheet-container {
    z-index: 9 !important;
    height: calc(20% - env(safe-area-inset-top) - 34px) !important;
    width: 50% !important;
    bottom: 100px !important;
    left: 30px !important;
  }

  .modal_img_category {
    width: 200%;
  }
}

@media screen and (max-width: 992px) {
  .react-modal-sheet-container {
    height: calc(60% - env(safe-area-inset-top) - 34px) !important;
    width: 100% !important;
    bottom: 0px !important;
    left: 0px !important;
  }

  .modal_img_category {
    width: 100%;
  }
}

@media screen and (max-width: 568px) {
  .react-modal-sheet-container {
    height: calc(100% - env(safe-area-inset-top) - 34px) !important;
    position: relative !important;
  }

  .decoration-fancy {
    display: flex;
    background-color: transparent;
    width: 100%;
    z-index: 98;
    transition: all 450ms ease;
  }

  .modal_detail {
    padding: 20px;
    background-color: white;
  }
}

@media screen and (max-width: 1023.991px) {
  .modal_detail_h2 {
    font-size: 18px;
  }
}

@media screen and (min-width: 1440px) {
  .decoration-fancy span {
    padding-left: 2vh;
  }
}
@media screen and (min-width: 1920px) {
  .decoration-fancy span {
    padding-left: 3vh;
  }
}
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
